<script setup lang="ts">
// vue
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

// composables
import { useFetch } from '@/modules/core/composables/useFetch'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'

// shared components
import VSelect from '@/modules/investing/components/v-select.vue'
import { RouterLinkBack, VBadge, VButtonInvisible, VSection, VSkeletonBar, VStats } from '@/modules/shared/components'

////////////////////////////////////////////////////////////////////////////////
// Utils
////////////////////////////////////////////////////////////////////////////////

const createShareholderId = (id: string, type: string) => `${type}:${id}`

const transformDocuments = (documents: Document[]) => {
  return documents.map((doc) => ({
    ...doc,
    selectedShareholder:
      doc.shareholder_type && doc.shareholder_id ? createShareholderId(doc.shareholder_id, doc.shareholder_type) : '',
  }))
}

////////////////////////////////////////////////////////////////////////////////
// State
////////////////////////////////////////////////////////////////////////////////

interface Document {
  id: string
  attachment_original_file_name: string
  attachment_view_url: string
  shareholder_id: string
  shareholder_type: string
  successful_matching_method: string
}

interface FilingLocator {
  id: string
  fileable: {
    id: string
    name: string
  }
  documents: Document[]
  investors: any[]
  shareholders: any[]
}

const filingLocator = ref<FilingLocator>(null)
const documents = ref([])
const shareholders = ref([])

////////////////////////////////////////////////////////////////////////////////
// Computed state
////////////////////////////////////////////////////////////////////////////////

const shareholderOptions = computed(() => {
  const selectedValues = documents.value.map((doc) => doc.selectedShareholder)

  return shareholders.value
    .map((shareholder) => {
      const value = `${shareholder.type}:${shareholder.id}`
      return {
        label: shareholder.name,
        value,
        disabled: selectedValues.includes(value),
      }
    })
    .sort((a, b) => a.label?.toLowerCase()?.localeCompare(b.label?.toLowerCase()))
})

const getShareholderOptions = (currentSelected: string) => {
  const selectedValues = documents.value
    .map((doc) => doc.selectedShareholder)
    .filter((value) => value !== currentSelected) // exclude current doc's selection

  return shareholders.value
    .map((shareholder) => {
      const value = `${shareholder.type}:${shareholder.id}`
      return {
        label: shareholder.name,
        value,
        disabled: selectedValues.includes(value),
      }
    })
    .sort((a, b) => a.label?.toLowerCase()?.localeCompare(b.label?.toLowerCase()))
}

////////////////////////////////////////////////////////////////////////////////
// Stats
////////////////////////////////////////////////////////////////////////////////

const unassignedDocuments = computed(() => {
  return documents.value.filter((doc) => !doc.selectedShareholder)
})

const unassignedShareholders = computed(() => {
  return shareholders.value.filter((shareholder) => {
    return !documents.value.some(
      (doc) => doc.selectedShareholder === createShareholderId(shareholder.id, shareholder.type),
    )
  })
})

////////////////////////////////////////////////////////////////////////////////
// Commands
////////////////////////////////////////////////////////////////////////////////

const assignDocumentToShareholder = async (document: Document, shareholder: string) => {
  console.log(`Assigning "${document.attachment_original_file_name}" to "${shareholder}"`)

  const [shareholder_type, shareholder_id] = shareholder === null ? [null, null] : shareholder.split(':')

  await useFetch(`/v4/${slug}/tax/documents/${document.id}/manually-assign-shareholder`).post({
    shareholder_id,
    shareholder_type,
  })

  fetch()
}

////////////////////////////////////////////////////////////////////////////////
// Query
////////////////////////////////////////////////////////////////////////////////

const { filingLocatorId, slug } = useRoute().params as { filingLocatorId: string; slug: string }

const fetch = async () => {
  const filingLocatorData = await useFetch(`/v4/${slug}/tax/filing-locators/${filingLocatorId}`).get<FilingLocator>()
  filingLocator.value = filingLocatorData
}

watch(
  () => filingLocator.value,
  (newValue) => {
    if (newValue) {
      documents.value = transformDocuments(newValue.documents)
      shareholders.value = newValue.shareholders
    }
  },
  { immediate: true },
)

const { skeleton, hideSkeleton, showSkeleton } = useSkeleton()

onMounted(async () => {
  showSkeleton()
  await fetch()
  hideSkeleton()
})
</script>

<template>
  <div class="p-8">
    <div class="mb-4">
      <RouterLinkBack :to="{ name: 'tax.filing-locators', params: { slug } }">
        <VButtonInvisible>← Back to all filing locators</VButtonInvisible>
      </RouterLinkBack>
    </div>
    <VSection :label="`${filingLocator?.fileable?.name} / ${filingLocator?.tax_year} / Documents`" class="mb-8">
      <div class="mb-4">
        <VStats
          :skeleton="skeleton"
          :stats="[
            {
              colspan: 1,
              label: '# Files',
              type: 'number',
              value: documents.length,
            },
            {
              colspan: 1,
              label: '# Files Unassigned',
              type: 'number',
              value: unassignedDocuments.length,
            },
            {
              colspan: 1,
              label: '# Investors',
              type: 'number',
              value: shareholders.length,
            },
            {
              colspan: 1,
              label: '# Investors Unassigned',
              type: 'number',
              value: unassignedShareholders.length,
            },
          ]"
        />
      </div>
      <div class="relative rounded-lg border border-gray-200">
        <table class="rounded-lg">
          <thead class="stick">
            <tr>
              <th class="w-1/2 bg-white/50">File Name</th>
              <th class="bg-white/50">Investor</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="skeleton">
              <tr v-for="n in [1, 2, 3, 4, 5, 6]" :key="n">
                <td v-for="_m in [1, 2]" class="px-3 py-2.5">
                  <VSkeletonBar />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="document in documents">
                <td class="border-r-[1px] border-gray-300 px-3 text-sm text-gray-700">
                  <div class="flex items-center justify-between">
                    <a :href="document.attachment_view_url" class="hyperlink" target="_blank">
                      {{ document.attachment_original_file_name }}
                    </a>
                    <div class="flex cursor-default items-center space-x-2">
                      <div
                        v-if="
                          document.selectedShareholder &&
                          !shareholderOptions.map((o) => o.value).includes(document.selectedShareholder)
                        "
                        class="group relative z-50 hover:block"
                      >
                        <div
                          class="rounded-full border-none bg-white font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/20 hover:ring-gray-700/30"
                        >
                          <VBadge color="red">Mismatch</VBadge>
                        </div>
                        <div
                          class="absolute bottom-full left-1/2 hidden w-80 -translate-x-1/2 transform pb-2 group-hover:block"
                        >
                          <!-- <div class="rounded-md bg-white px-3 py-1.5 text-sm text-gray-700 shadow-lg ring-[1px] ring-gray-700/20"> -->
                          <div class="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm text-gray-100 shadow-lg">
                            Expected "{{ document.selectedShareholder }}"
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="!shareholderOptions.map((o) => o.value).includes(document.selectedShareholder)"
                        class="group relative z-50 hover:block"
                      >
                        <div
                          class="rounded-full border-none bg-white font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/20 hover:ring-gray-700/30"
                        >
                          <VBadge color="red">Unassigned</VBadge>
                        </div>
                        <div
                          class="absolute bottom-full left-1/2 hidden w-80 -translate-x-1/2 transform pb-2 group-hover:block"
                        >
                          <!-- <div class="rounded-md bg-white px-3 py-1.5 text-sm text-gray-700 shadow-lg ring-[1px] ring-gray-700/20"> -->
                          <div class="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm text-gray-100 shadow-lg">
                            Expected "{{ document.selectedShareholder }}"
                          </div>
                        </div>
                      </div>
                      <VBadge>{{ document.successful_matching_method }}</VBadge>
                    </div>
                  </div>
                </td>
                <td>
                  <VSelect
                    v-model="document.selectedShareholder"
                    @update:modelValue="(value) => assignDocumentToShareholder(document, value)"
                    :options="getShareholderOptions(document.selectedShareholder)"
                    placeholder="-- Select investor --"
                    size="sm"
                  ></VSelect>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </VSection>
  </div>
</template>

<style scoped>
table {
  @apply w-full border-separate border-spacing-0 overflow-x-auto;
}

thead {
  @apply text-[12px] text-xs uppercase text-gray-700;
}

thead.stick th {
  @apply sticky top-0 z-50;
}

th {
  @apply min-w-[100px] whitespace-nowrap border-b border-r border-gray-200 px-3 py-3 backdrop-blur sm:min-w-[10px];
}

thead:first-child th:first-child {
  @apply rounded-tl-lg;
}

th:last-child {
  @apply rounded-tr-lg;
}

@media (min-width: 640px) {
  th.fixed-column {
    @apply first:sticky first:left-0 first:z-10 first:border-r;
  }

  td.fixed-column {
    @apply first:sticky first:left-0 first:z-[1] first:min-w-[320px] first:max-w-[480px] first:border-r first:bg-white;
  }

  tfoot td.fixed-column {
    @apply rounded-bl-lg;
  }
}

tbody {
  @apply text-sm text-gray-700;
}

tbody td {
  @apply whitespace-nowrap border-b border-r border-gray-200;
}

th:first-child,
td:first-child {
  @apply border-l-0;
}

th:last-child,
td:last-child {
  @apply border-r-0;
}

tfoot {
  @apply text-sm font-medium uppercase tracking-wide;
}

tfoot td {
  @apply whitespace-nowrap border-r border-gray-200 text-gray-900;
}

table tbody td,
table tfoot td {
  border-top: none !important;
}

table tbody tr:last-child td {
  border-bottom: none !important;
}
</style>
