<script setup lang="ts">
// vue
import { computed, nextTick, ref, watch } from 'vue'
import { onClickOutside, useFocus, useMagicKeys } from '@vueuse/core'

// composables
import { useSearch } from '@/modules/shared/composables/use-search'

// shared components
import { VIcon } from '@/modules/shared/components'

// utils
import { search } from '@/modules/shared/utils/deep-search'

///////////////////////////////////////////////////////////////////////////////

type Option = {
  disabled?: boolean
  label: string
  value: string
}

const props = withDefaults(
  defineProps<{
    modelValue: string | null
    description?: string
    disabled?: boolean
    error?: string
    id?: string
    inline?: boolean
    label?: string
    name?: string
    options: Option[]
    placeholder?: string
    property?: string
    required?: boolean
    v$?: any
    size?: string
    customLabel?: boolean
  }>(),
  {
    disabled: false,
    inline: false,
    placeholder: '',
    size: 'md',
    customLabel: false,
  },
)

const emit = defineEmits(['update:modelValue'])

///////////////////////////////////////////////////////////////////////////////
// Option list
///////////////////////////////////////////////////////////////////////////////

const optionListRef = ref(null)
const isOptionListOpen = ref(false)

const openOptionList = async () => {
  if (props.disabled) return

  isOptionListOpen.value = true
  await nextTick()
  isSearchInputFocused.value = true
}

const closeOptionList = () => {
  isOptionListOpen.value = false
  isSearchInputFocused.value = false
  clearQuery()
}

onClickOutside(optionListRef, (_event) => closeOptionList())

const unfocusOptionList = (event: FocusEvent) => {
  const relatedTarget = event.relatedTarget as HTMLElement | null

  // if the next focused element is NOT inside the dropdown target
  if (!optionListRef.value?.contains(relatedTarget)) {
    closeOptionList()
  }
}

///////////////////////////////////////////////////////////////////////////////
// Search
///////////////////////////////////////////////////////////////////////////////

const { inputRef: searchInputRef, clearQuery, query } = useSearch()
const { focused: isSearchInputFocused } = useFocus(searchInputRef)

const filteredOptions = computed(() => search(props.options, query.value))

watch(
  () => props.modelValue,
  () => clearQuery(),
)

///////////////////////////////////////////////////////////////////////////////
// Selection logic
///////////////////////////////////////////////////////////////////////////////

const selectedValue = computed(() => props.modelValue)

const selectedOption = computed(() => props.options.find((option) => option.value === selectedValue.value))

const selectOption = (value: string) => {
  if (props.modelValue === value) {
    emit('update:modelValue', null)
  } else {
    emit('update:modelValue', value)
  }
  closeOptionList()
}

const confirmFirstOption = () => {
  const firstOption = filteredOptions.value.find((option) => !option.disabled)
  if (firstOption) {
    selectOption(firstOption.value)
  }
}

///////////////////////////////////////////////////////////////////////////////
// Highlight list
///////////////////////////////////////////////////////////////////////////////

const highlightedIndex = ref(-1)

watch(filteredOptions, () => {
  highlightedIndex.value = -1
})

const moveHighlight = (direction: 'up' | 'down') => {
  if (!isOptionListOpen.value || filteredOptions.value.length === 0) return

  const enabledOptions = filteredOptions.value.filter((opt) => !opt.disabled)
  if (enabledOptions.length === 0) return

  const currentOption = enabledOptions[highlightedIndex.value] || null
  let index = enabledOptions.indexOf(currentOption)

  if (direction === 'down') {
    index = index < enabledOptions.length - 1 ? index + 1 : 0
  } else {
    index = index > 0 ? index - 1 : enabledOptions.length - 1
  }

  highlightedIndex.value = filteredOptions.value.indexOf(enabledOptions[index])
}

const confirmHighlightedOption = () => {
  const option = filteredOptions.value[highlightedIndex.value]
  if (option && !option.disabled) {
    selectOption(option.value)
  }
}

///////////////////////////////////////////////////////////////////////////////
// Keyboard Events
///////////////////////////////////////////////////////////////////////////////

const { arrowDown, arrowUp, escape } = useMagicKeys()

watch(arrowDown, (v) => v && moveHighlight('down'))
watch(arrowUp, (v) => v && moveHighlight('up'))
watch(escape, (v) => v && closeOptionList())

const handleEnter = () => {
  if (!isOptionListOpen.value) return

  if (highlightedIndex.value >= 0) {
    confirmHighlightedOption()
  } else {
    confirmFirstOption()
  }
}

///////////////////////////////////////////////////////////////////////////////
// Validation & styling
///////////////////////////////////////////////////////////////////////////////

const error = computed(() => props.error || (props.v$ && props.v$[props.property || props.name]?.$errors[0]?.$message))

const selectClass = computed(() => {
  let classes = ['relative block w-full bg-white']
  switch (props.size) {
    case 'sm':
      classes.push('h-[38px] py-2 pl-3 pr-8 text-sm')
      break
    case 'md':
      classes.push('h-[42px] px-3 py-2')
      break
    case 'lg':
      classes.push('text-lg')
      break
  }
  if (error.value) {
    classes.push('border-red-300 focus:border-red-300 focus:ring-red-200')
  } else {
    classes.push('border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50')
  }

  if (props.inline) {
    classes.push('border-none')
  } else {
    classes.push('rounded-md border')
  }

  if (props.disabled) {
    classes.push('bg-gray-50 text-gray-500')
  } else {
    classes.push('cursor-pointer')
  }

  return classes
})
</script>

<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700" v-if="label">
      <span>{{ label }}</span>
      <span v-if="required">*</span>
    </label>
    <div class="relative" ref="optionListRef" :class="{ 'z-[2]': isOptionListOpen }" @focusout="unfocusOptionList">
      <div @focus="openOptionList" @click="openOptionList" :class="selectClass" tabindex="0">
        <div class="h-full overflow-hidden pr-3" v-if="!customLabel">
          {{ selectedOption?.label || placeholder }}
        </div>
        <template v-else>
          <slot></slot>
        </template>
        <VIcon name="chevron_down" class="absolute right-2 top-1/2 h-4 w-4 -translate-y-1/2" />
      </div>

      <ul
        v-show="!disabled && isOptionListOpen"
        class="x-0 absolute mx-px mt-1 max-h-64 w-full divide-y-0 divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-[1px] ring-gray-700/20"
      >
        <li>
          <div class="relative flex-grow">
            <input
              v-model="query"
              @keydown.enter.prevent="handleEnter"
              type="text"
              class="relative z-40 w-full rounded-none rounded-tl-md border-b border-l-0 border-r-0 border-t-0 border-gray-200 bg-transparent text-sm focus:border-gray-200 focus:ring focus:ring-sky-200 focus:ring-opacity-0"
              :placeholder="`Search by anything`"
              ref="searchInputRef"
            />
            <div class="pointer-events-none absolute inset-y-0 right-0 z-50 flex items-center pr-3">
              <button
                @click="clearQuery"
                class="curosr-pointer pointer-events-auto inline-block rounded-md bg-gray-100 p-1"
                v-show="query"
              >
                <VIcon name="x" class="h-3 w-3 text-gray-400" />
              </button>
            </div>
          </div>
        </li>
        <template v-for="(option, index) in filteredOptions" :key="option.value">
          <li
            v-if="option.disabled"
            class="flex items-center space-x-2 border-b bg-gray-50 px-3 py-1.5 pr-6 text-sm font-medium text-gray-400"
          >
            {{ option.label }}
          </li>
          <li
            v-else
            @mousedown.prevent="selectOption(option.value)"
            :class="[
              'flex cursor-pointer items-center space-x-2 border-b px-3 py-1.5 pr-6 text-sm font-medium',
              selectedValue === option.value
                ? 'bg-sky-100 text-sky-900'
                : highlightedIndex === index
                  ? 'bg-sky-50 text-sky-900'
                  : 'text-gray-700 hover:bg-gray-100',
            ]"
          >
            {{ option.label }}
          </li>
        </template>
      </ul>
      <template v-if="error && !inline">
        <p class="mt-2 text-sm text-red-500">{{ error }}</p>
      </template>
      <template v-if="description">
        <p class="mt-2 text-sm text-gray-500" :id="`${id}-description`">{{ description }}</p>
      </template>
    </div>
  </div>
</template>
