<script setup lang="ts">
import { get, isEmpty } from 'lodash'
import { ref, onMounted, toRaw, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useModal } from '@/modules/shared/composables/use-modal'
import {
  ActionItem,
  ActionsGroup,
  ActionsMenu,
  IconCheck,
  IconDownload,
  IconLinkExternal,
  IconFileX,
  IconLayoutRight,
  IconPaperclip,
  IconThumbsUp,
  IconTrash,
  RouterLinkBack,
  VBadge,
  VButton,
  VButtonInvisible,
  VDropdownImproved,
  VIcon,
  VModal,
  VTable,
  VTextField,
  VSection,
} from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import { useFilingLocatorStore } from '../stores/filing-locator-store'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

function capitalize(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

function distributionStatus(filing_locator: any) {
  const entity = unflattenKey(filing_locator, 'entity')

  if (entity.investors.every((i) => !i.has_tax_documents)) {
    return 'Pending distribution'
  }

  if (entity.investors.some((i) => i.has_tax_documents)) {
    if (entity.investors.every((i) => i.has_tax_documents)) {
      return 'Distributed'
    }

    const unmatched_count = entity.investors.filter((i) => !i.has_tax_documents).length
    return `Distributed (${unmatched_count} unmatched)`
  }
}

///////////////////////////////////////////////////////////////////////////////

const { t } = useExtendedI18n()
const route = useRoute()

const authStore = useAuthStore()

const slideInModal = useModal()

const filingLocatorStore = useFilingLocatorStore()

const skeleton = ref(true)

const taxYear = new Date().getFullYear() - 1
const default_year = route.query.year || taxYear

onMounted(async () => {
  await filingLocatorStore.list(default_year)
  skeleton.value = false
})

const selected_years = ref([default_year])
const taxYearOptions = computed(() => {
  const years = [
    { label: '2024', value: '2024' },
    { label: '2023', value: '2023' },
    { label: '2022', value: '2022' },
  ]

  return years
})
const selectedYearsString = computed(() =>
  selected_years.value.length > 0 ? selected_years.value.join(', ') : '(not selected)',
)

watch(
  () => selected_years.value,
  async (new_years, old_years) => {
    if (new_years[0] !== old_years[0]) {
      skeleton.value = true
      window.history.replaceState(null, null, `?year=${new_years[0]}`)
      await filingLocatorStore.list(new_years[0])
      skeleton.value = false
    }
  },
)

const is_missing_information = (filing_locator) => {
  const entity = unflattenKey(filing_locator, 'entity')
  const errors = [
    isEmpty(filing_locator.administrator),
    isEmpty(filing_locator.formation_date),
    isEmpty(filing_locator.entity_legal_name),
    ...entity.investors.map((investor) => isEmpty(investor.tax_id)),
    isEmpty(filing_locator.entity_name),
    isEmpty(filing_locator.tax_id),
    isEmpty(filing_locator.type),
  ]
  return errors.some((value) => value === true)
}

const available_return = (item) => {
  return item.prepared_return_document_url
}

const isLoading = ref(false)

const submit = async () => {
  isLoading.value = true

  await filingLocatorStore.patch(toRaw(filingLocatorStore.item))

  isLoading.value = false

  slideInModal.close()
}

const isSiteAdmin = computed(() => authStore.is_site_admin)
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <RouterLinkBack>
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>
    <VSection label="Taxes">
      <div class="z-50 flex items-center justify-between">
        <div class="mb-6 flex items-center space-x-2">
          <VDropdownImproved v-model="selected_years" :multi="false" aligned="left" :options="taxYearOptions">
            <VButton size="md">
              <div class="flex items-center space-x-2">
                <div>Filter by year {{ selectedYearsString }}</div>
                <VIcon name="chevron_selector_vertical" />
              </div>
            </VButton>
          </VDropdownImproved>
        </div>
      </div>
      <VTable
        :columns="[
          {
            key: 'entity_name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            is_visible: true,
            fixed: true,
          },
          {
            key: 'entity_legal_name',
            name: capitalize(t('shared.legal name')),
            type: 'string',
            is_visible: false,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'return_status',
            name: capitalize(t('shared.return status')),
            type: 'string',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'view_return',
            name: 'View return',
            type: 'string',
            is_visible: true,
          },
          {
            key: 'missing_information',
            name: capitalize(t('shared.missing information')),
            type: 'string',
            is_visible: true,
          },
          {
            key: 'locator',
            name: capitalize(t('shared.locator')),
            type: 'string',
            align: 'center',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'filing_requirement',
            name: capitalize(t('shared.filing requirement')),
            type: 'string',
            align: 'center',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'entity_type',
            name: capitalize(t('shared.type')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'incorporated_state',
            name: capitalize(t('shared.incorporated state')),
            type: 'string',
            align: 'center',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'tax_id',
            name: t('shared.EIN'),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'state_filing',
            name: capitalize(t('shared.state filing')),
            type: 'string',
            align: 'center',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'initial_return',
            name: capitalize(t('shared.initial return')),
            type: 'boolean',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'has_activity',
            name: capitalize(t('shared.has activity')),
            type: 'boolean',
            align: 'center',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'final_return',
            name: capitalize(t('shared.final return')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'current_value',
            name: capitalize(t('shared.current value')),
            type: 'currency',
            align: 'center',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'basis',
            name: capitalize(t('shared.basis')),
            type: 'string',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'external_k_1',
            name: capitalize(t('shared.external K-1')),
            type: 'boolean',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'internal_k_1',
            name: capitalize(t('shared.internal K-1')),
            type: 'string',
            align: 'center',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'federal_status',
            name: capitalize(t('shared.federal status')),
            type: 'string',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'state_status',
            name: capitalize(t('shared.state status')),
            type: 'string',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'federal_extension_status',
            name: capitalize(t('shared.federal extension status')),
            type: 'string',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
          {
            key: 'state_extension_status',
            name: capitalize(t('shared.state extension status')),
            type: 'string',
            is_accessible: isSiteAdmin,
            is_visible: true,
          },
        ]"
        :items="filingLocatorStore.items"
        :name="`entities-taxes-list`"
        :skeleton="skeleton"
        :slots="[
          'entity_name',
          'external_k_1',
          'federal_status',
          'federal_extension_status',
          'final_return',
          'internal_k_1',
          'missing_information',
          'return_status',
          'state_extension_status',
          'state_status',
          'view_return',
          'actions',
        ]"
      >
        <template #entity_name="{ item }">
          <div class="mb-1 w-[400px]">
            <RouterLink
              class="hyperlink"
              :to="{
                name: 'investing.entity-overview',
                params: { entity_id: item.entity_id, entity_type: item.entity_type.toLowerCase() },
              }"
            >
              {{ item.entity_name }}
            </RouterLink>
          </div>
          <div class="flex flex-wrap items-center justify-start space-x-2">
            <div class="whitespace-normal text-sm text-gray-600" v-if="!isEmpty(item.entity_legal_name)">
              {{ item.entity_legal_name }}
            </div>
          </div>
        </template>
        <template #missing_information="{ item }">
          <VBadge
            color="red"
            v-if="is_missing_information(item)"
            @click="filingLocatorStore.select(item.id), slideInModal.open()"
          >
            <span>View missing information</span>
          </VBadge>
        </template>
        <template #final_return="{ item }">
          <template v-if="authStore.is_site_admin">
            <input
              :checked="item.final_return"
              @change="(event) => filingLocatorStore.set_final_return(item.id, event.target.checked)"
              type="checkbox"
              class="inverse inline-block h-4 w-4 cursor-pointer rounded border-gray-300 text-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 active:translate-y-px"
              style="border-color: rgb(209, 213, 219)"
            />
          </template>
          <template v-else>
            <span>{{ item.final_return ? 'Yes' : 'No' }}</span>
          </template>
        </template>
        <template #internal_k_1="{ item }">
          <input
            :checked="item.internal_k_1"
            type="checkbox"
            class="inverse inline-block h-4 w-4 cursor-pointer rounded border-gray-300 text-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 active:translate-y-px"
            style="border-color: rgb(209, 213, 219)"
            disabled
          />
        </template>
        <template #federal_status="{ item }">
          <select
            :value="item.federal_status"
            @change="filingLocatorStore.set_federal_status(item.id, $event.target.value)"
            :v-model="item.federal_status"
            id="federal_status"
            name="federal_status"
            class="block h-[42px] w-full cursor-pointer border-none bg-transparent py-1.5 pl-3 pr-10 text-base hover:bg-white focus:outline-none sm:text-sm"
          >
            <option v-for="federal_status in filingLocatorStore.extension_statuses" :value="federal_status.value">
              {{ federal_status.text }}
            </option>
          </select>
        </template>
        <template #state_status="{ item }">
          <select
            :value="item.state_status"
            @change="filingLocatorStore.set_state_status(item.id, $event.target.value)"
            id="state_status"
            name="state_status"
            class="block h-[42px] w-full cursor-pointer border-none bg-transparent py-1.5 pl-3 pr-10 text-base hover:bg-white focus:outline-none sm:text-sm"
          >
            <option v-for="state_status in filingLocatorStore.extension_statuses" :value="state_status.value">
              {{ state_status.text }}
            </option>
          </select>
        </template>
        <template #federal_extension_status="{ item }">
          <select
            :value="item.federal_extension_status"
            @change="filingLocatorStore.set_federal_extension_status(item.id, $event.target.value)"
            id="federal_extension_status"
            name="federal_extension_status"
            class="block h-[42px] w-full cursor-pointer border-none bg-transparent py-1.5 pl-3 pr-10 text-base hover:bg-white focus:outline-none sm:text-sm"
          >
            <option
              v-for="federal_extension_status in filingLocatorStore.extension_statuses"
              :value="federal_extension_status.value"
            >
              {{ federal_extension_status.text }}
            </option>
          </select>
        </template>
        <template #state_extension_status="{ item }">
          <select
            :value="item.state_extension_status"
            @change="filingLocatorStore.set_state_extension_status(item.id, $event.target.value)"
            id="state_extension_status"
            name="state_extension_status"
            class="block h-[42px] w-full cursor-pointer border-none bg-transparent py-1.5 pl-3 pr-10 text-base hover:bg-white focus:outline-none sm:text-sm"
          >
            <option
              v-for="state_extension_status in filingLocatorStore.extension_statuses"
              :value="state_extension_status.value"
            >
              {{ state_extension_status.text }}
            </option>
          </select>
        </template>
        <template #return_status="{ item }">
          <select
            :value="item.return_status"
            @change="filingLocatorStore.set_return_status(item.id, $event.target.value)"
            id="location"
            name="location"
            class="relative block rounded-md border border-gray-300 px-3 py-1.5 pr-10 text-sm font-medium text-gray-700 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
          >
            <option v-for="return_status in filingLocatorStore.return_statuses" :value="return_status.value">
              {{ return_status.text }}
            </option>
          </select>
        </template>
        <template #import_entity="{ item }">
          <VButton
            class="flex items-center space-x-1.5 py-1.5"
            :click="() => filingLocatorStore.import_entity(item.entity_id)"
            :disabled="!item.locator"
          >
            <span>Import Entity</span>
          </VButton>
        </template>
        <template #print_return="{ item }">
          <VButton
            class="flex items-center space-x-1.5 py-1.5"
            :click="() => filingLocatorStore.print_return(item.entity_id)"
            :disabled="!item.locator"
          >
            <span>Print Return</span>
          </VButton>
        </template>
        <template #create_efile="{ item }">
          <VButton
            class="flex items-center space-x-1.5 py-1.5"
            :click="() => filingLocatorStore.create_efile(item.entity_id)"
            :disabled="!item.locator"
          >
            <span>Create E-File</span>
          </VButton>
        </template>
        <template #efiles_validation="{ item }">
          <VButton
            class="flex items-center space-x-1.5 py-1.5"
            :click="() => filingLocatorStore.efiles_validation(item.entity_id)"
            :disabled="!item.locator"
          >
            <span>Validate E-Files</span>
          </VButton>
        </template>
        <template #view_return="{ item }">
          <div class="flex items-center space-x-2 px-3">
            <VButton
              class="flex items-center space-x-1.5 py-1.5"
              :class="{
                'cursor-not-allowed  opacity-50': !available_return(item),
              }"
              :disabled="!available_return(item)"
            >
              <a v-if="available_return(item)" :href="available_return(item)" target="_blank">
                <span>View return</span>
              </a>
              <span v-else>View return</span>
            </VButton>
            <VButton
              v-if="item.return_status === 'needs_approval' && available_return(item)"
              :disabled="item.return_status !== 'needs_approval'"
              :click="() => filingLocatorStore.approve_prepared_return(item.id)"
            >
              <div class="flex items-center space-x-2">
                <span class="">Approve return and distribute K-1s</span>
                <span class="opacity-70"><IconThumbsUp class="h-4 w-4" /></span>
              </div>
            </VButton>
            <VButton
              v-if="unflattenKey(item, 'investor_documents').length > 0"
              :click="() => filingLocatorStore.recall_k1s(item.id)"
            >
              <div class="flex items-center space-x-2">
                <span class="">Recall K-1s</span>
              </div>
            </VButton>
            <span class="cursor-pointer">
              <RouterLink
                v-if="authStore.is_site_admin"
                :to="{
                  name: 'tax.filing-locator.documents',
                  params: { filingLocatorId: item.id },
                }"
              >
                <VBadge
                  v-if="item.return_status === 'approved_for_filing'"
                  @click="filingLocatorStore.select(item.id), slideInModal.open()"
                  >{{ distributionStatus(item) }}</VBadge
                >
              </RouterLink>
              <VBadge
                v-else-if="item.return_status === 'approved_for_filing'"
                @click="filingLocatorStore.select(item.id), slideInModal.open()"
                >{{ distributionStatus(item) }}</VBadge
              >
            </span>
          </div>
        </template>
        <template #actions="{ item }">
          <div class="flex items-center space-x-2">
            <div class="relative inline-block text-left">
              <ActionsMenu>
                <ActionsGroup label="Details">
                  <ActionItem
                    :href="get(item, 'entity.links.url')"
                    :icon="IconLinkExternal"
                    target="_blank"
                    text="View entity"
                    tag="a"
                  />
                  <ActionItem
                    :icon="IconLayoutRight"
                    text="View information"
                    @click="filingLocatorStore.select(item.id), slideInModal.open()"
                  />
                </ActionsGroup>
                <ActionsGroup label="Prepared return">
                  <!-- prettier-ignore -->
                  <ActionItem
                          v-if="authStore.is_site_admin"
                          :icon="IconPaperclip"
                          text="Attach prepared return"
                        >
                          <input
                            type="file"
                            class="hidden"
                            @change="
                              filingLocatorStore.attach_prepared_return_document(
                                item.id,
                                $event.target.files
                              )
                            "
                          />
                        </ActionItem>
                  <ActionItem
                    :disabled="!available_return(item)"
                    :icon="IconThumbsUp"
                    text="Approve prepared return"
                    @click="filingLocatorStore.approve_prepared_return(item.id)"
                  />
                  <ActionItem
                    :disabled="!available_return(item)"
                    :icon="IconFileX"
                    text="Reject prepared return"
                    @click="filingLocatorStore.reject_prepared_return(item.id)"
                  />
                  <ActionItem
                    :disabled="!available_return(item)"
                    :icon="IconDownload"
                    text="Download prepared return"
                  />
                  <ActionItem
                    v-if="authStore.is_site_admin"
                    :disabled="!available_return(item)"
                    :icon="IconTrash"
                    text="Remove prepared return"
                    @click="filingLocatorStore.remove_prepared_return_document(item.id)"
                  />
                </ActionsGroup>
                <ActionsGroup label="Finalized return">
                  <!-- prettier-ignore -->
                  <ActionItem
                          v-if="authStore.is_site_admin"
                          :icon="IconPaperclip"
                          text="Attach finalized return"
                        >
                          <input
                            class="hidden"
                            type="file"
                            @change="
                              filingLocatorStore.attach_finalized_return_document(
                                item.id,
                                $event.target.files
                              )
                            "
                          />
                        </ActionItem>
                  <ActionItem
                    v-if="authStore.is_site_or_group_admin"
                    :icon="IconPaperclip"
                    text="Attach investor return(s)"
                  >
                    <input
                      class="hidden"
                      multiple
                      type="file"
                      @change="filingLocatorStore.attach_investor_return_document(item.id, $event.target.files)"
                    />
                  </ActionItem>
                  <ActionItem
                    :disabled="!item.finalized_return_document_url"
                    :icon="IconDownload"
                    text="Download finalized return"
                  />
                  <ActionItem
                    v-if="authStore.is_site_admin"
                    :disabled="!item.finalized_return_document_url"
                    :icon="IconTrash"
                    text="Remove finalized return"
                    @click="filingLocatorStore.remove_finalized_return_document(item.id)"
                  />
                </ActionsGroup>
                <ActionsGroup label="GoSystem actions">
                  <!-- prettier-ignore -->
                  <ActionItem
                        @click="() => filingLocatorStore.import_entity(item.entity_id)"
                        :icon="IconLinkExternal"
                        target="_blank"
                        text="Import Entity"
                        tag="a"
                      />
                  <ActionItem
                    @click="() => filingLocatorStore.print_return(item.entity_id)"
                    :icon="IconLinkExternal"
                    target="_blank"
                    text="Print Return"
                    tag="a"
                  />
                  <ActionItem
                    @click="() => filingLocatorStore.create_efile(item.entity_id)"
                    :icon="IconLinkExternal"
                    target="_blank"
                    text="Create EFile"
                    tag="a"
                  />
                  <ActionItem
                    @click="() => filingLocatorStore.efiles_validation(item.entity_id)"
                    :icon="IconLinkExternal"
                    target="_blank"
                    text="Validate EFiles"
                    tag="a"
                  />
                  <ActionItem :icon="IconLinkExternal" target="_blank" text="Distribute K-1s" tag="a" />
                </ActionsGroup>
              </ActionsMenu>
            </div>
          </div>
        </template>
      </VTable>
    </VSection>
    <VModal :modalStore="slideInModal">
      <template v-slot:main>
        <div class="grid grid-cols-1 gap-6 py-3">
          <VTextField v-model="filingLocatorStore.item.entity_name" label="Display name" />

          <VTextField v-model="filingLocatorStore.item.entity_legal_name" label="Legal name" />

          <hr class="my-6" />

          <fieldset>
            <label class="block text-sm font-medium text-gray-700">Type</label>
            <select
              v-model="filingLocatorStore.item.type"
              class="mt-1 block h-[42px] w-full cursor-pointer rounded-md border-gray-300 py-1.5 pl-3 pr-10 text-base text-gray-700 shadow-sm hover:bg-white focus:border-sky-300 focus:outline-none focus:ring focus:ring-sky-200 focus:ring-opacity-50"
            >
              <option v-for="type in filingLocatorStore.entity_types">
                {{ type }}
              </option>
            </select>
          </fieldset>

          <VTextField
            v-model="filingLocatorStore.item.formation_date"
            label="Formation date"
            type="date_without_timezone"
          />

          <VTextField v-model="filingLocatorStore.item.tax_id" label="Tax ID" placeholder="00-0000000" type="text" />

          <hr class="-mx-6 my-6 h-px bg-gray-200" />

          <div>Administrator</div>

          <div class="relative">
            <VTextField v-model="filingLocatorStore.item.administrator" disabled="true" type="text" />

            <a
              :href="get(filingLocatorStore.item, 'entity.links.edit_url')"
              class="absolute right-3 top-2 mt-1 block text-sky-600 hover:text-sky-900"
              target="_blank"
              >Edit administrator</a
            >
          </div>

          <hr class="-mx-6 my-6 h-px bg-gray-200" />

          <div>Investors</div>

          <VTable
            :columns="[
              {
                key: 'name',
                name: capitalize(t('shared.name')),
                type: 'string',
                is_visible: true,
              },
              {
                key: 'tax_id',
                name: t('shared.EIN'),
                type: 'string',
                is_visible: true,
              },
              {
                key: 'return_attached',
                name: capitalize(t('shared.return attached')),
                type: 'string',
                is_visible: true,
              },
            ]"
            :items="filingLocatorStore.item.investors"
            :name="`entities-taxes-entity-investors`"
            :slots="['return_attached']"
          >
            <template #return_attached="{ item }">
              <IconCheck class="h-5 w-5" v-if="item.has_tax_documents" />
            </template>
          </VTable>
        </div>
      </template>
      <template v-slot:footer>
        <VButton
          class="w-full"
          :class="{
            'cursor-not-allowed ': isLoading,
            'cursor-pointer ': !isLoading,
          }"
          :click="submit"
        >
          <span v-show="isLoading">Saving...</span>
          <span v-show="!isLoading">Save</span>
        </VButton>
      </template>
    </VModal>
  </TheLayout>
</template>

<style></style>
